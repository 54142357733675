// Whatsapp.jsx (Frontend)
import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";
import CircularProgress from "@mui/material/CircularProgress";

// Componentes personalizados (ajuste conforme sua estrutura de projeto)
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";

// Inicializa o socket, apontando para o backend
const socket = io("https://app.agntia.com:3001/", {
  transports: ["websocket"],
  secure: true, // Garante que a conexão é segura
  rejectUnauthorized: false, // Ajuste conforme necessário
  autoConnect: false, // Gerenciamento manual da conexão
});

const Whatsapp = () => {
  const [qrCode, setQrCode] = useState("");
  const [isLoadingQRCode, setIsLoadingQRCode] = useState(false);
  const [agentCreated, setAgentCreated] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyAge, setCompanyAge] = useState("");
  const [workHours, setWorkHours] = useState("");
  const [message, setMessage] = useState("");
  const [agntWpp, setAgntWpp] = useState(null);

  useEffect(() => {
    // Conecta o socket manualmente
    socket.connect();

    // Eventos do socket
    socket.on("connect", () => {
      console.log("✅ Conectado ao backend via Socket.IO.");
      fetchAgentStatus(); // Busca o status do agente ao conectar
    });

    socket.on("qr", ({ qr }) => {
      console.log("📷 QR Code recebido:", qr);
      setQrCode(qr); // Atualiza o estado com o QR code recebido
      setIsLoadingQRCode(false); // Para de exibir o loader de QR code
    });

    socket.on("authenticated", handleAuthentication);

    socket.on("pairing-code", (data) => {
      console.log("📲 Código de Pareamento Recebido:", data.code);
      setMessage(`Código de Pareamento Recebido: ${data.code}`);
    });

    socket.on("error", (err) => {
      console.error("❌ Erro no Socket.IO:", err);
      setMessage("Erro na conexão com o servidor. Tente novamente.");
      setIsLoadingQRCode(false);
    });

    socket.on("disconnect", () => {
      console.log("❌ Desconectado do backend.");
    });

    // Limpeza ao desmontar o componente
    return () => {
      socket.off("connect");
      socket.off("qr");
      socket.off("authenticated", handleAuthentication);
      socket.off("pairing-code");
      socket.off("error");
      socket.off("disconnect");
      socket.disconnect();
    };
  }, []);

  const handleAuthentication = async () => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");

    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.post(
          "https://app.agntia.com:3001/api/update-auth-status",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error(
          "Erro ao atualizar a autenticação no banco de dados:",
          error
        );
      }
    }

    // Atualize o status do agente para obter o novo estado de autenticação
    fetchAgentStatus();
  };

  const fetchAgentStatus = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setMessage("Erro: Token não encontrado. Faça login novamente.");
      return;
    }

    try {
      const profileResponse = await axios.get(
        "https://app.agntia.com:3001/api/user-profile",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { isAuthenticated, agntWpp } = profileResponse.data;

      if (isAuthenticated) {
        setIsAuthenticated(true);
        setAgntWpp(agntWpp);
        localStorage.setItem("isAuthenticated", "true");

        const contentResponse = await axios.get(
          "https://app.agntia.com:3001/api/get-wpp-content",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { wppContent } = contentResponse.data;

        if (wppContent && wppContent.length > 0) {
          generateQRCode();
        }
      } else {
        setIsAuthenticated(false);

        const contentResponse = await axios.get(
          "https://app.agntia.com:3001/api/get-wpp-content",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { wppContent } = contentResponse.data;

        setAgentCreated(wppContent.length > 0);

        if (wppContent.length > 0) {
          generateQRCode();
        }
      }
    } catch (error) {
      console.error("Erro ao verificar o status do agente:", error);
      setMessage("Erro ao verificar o status do agente. Tente novamente.");
    }
  };

  const createAgent = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setMessage("Erro: Token não encontrado. Faça login novamente.");
      return;
    }

    const fullContent = `Você é um gerente humano de uma empresa chamada ${companyName}. ${
      companyDescription ? "Descrição: " + companyDescription : ""
    } ${companyAge ? "Tempo de existência: " + companyAge : ""} ${
      workHours ? "Horário de trabalho: " + workHours : ""
    }`;

    try {
      const response = await axios.post(
        "https://app.agntia.com:3001/api/save-wpp-content",
        { content: fullContent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Conteúdo do WhatsApp salvo com sucesso.") {
        setAgentCreated(true);
        setMessage("Agente criado com sucesso!");
        setTimeout(() => {
          setMessage("");
          generateQRCode();
        }, 2000);
      }
    } catch (error) {
      console.error("Erro ao criar o agente:", error);
      setMessage("Erro ao criar o agente. Tente novamente.");
    }
  };

  const generateQRCode = () => {
    console.log("🔄 Solicitando QR code...");
    setIsLoadingQRCode(true);
    const token = localStorage.getItem("token");
    if (token) {
      socket.emit("init-whatsapp", { token });
    } else {
      setMessage("Erro: Token não encontrado. Faça login novamente.");
      setIsLoadingQRCode(false);
    }
  };

  return (
    <DashboardLayout>
      <Header title="Agnt.iA Whatsapp" hideIcons={true} />
      <VuiBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "transparent",
          position: "relative",
          padding: "20px",
        }}
      >
        {isAuthenticated ? (
          <div style={{ width: "100%", height: "100%" }}>
            {agntWpp && <ChatSection agntWpp={agntWpp} />}
          </div>
        ) : !agentCreated ? (
          <VuiBox
            sx={{
              maxWidth: "500px",
              width: "100%",
              padding: "30px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(10px)",
            }}
          >
            <VuiTypography
              variant="h4"
              sx={{
                textAlign: "center",
                marginBottom: "20px",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Criação de Agente WhatsApp
            </VuiTypography>
            <VuiBox
              component="form"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <input
                type="text"
                placeholder="Nome da Empresa"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "15px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
              <input
                type="text"
                placeholder="O que a Empresa Faz"
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "15px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
              <input
                type="text"
                placeholder="Tempo de Existência da Empresa"
                value={companyAge}
                onChange={(e) => setCompanyAge(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "15px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
              <input
                type="text"
                placeholder="Informações adicionais"
                value={workHours}
                onChange={(e) => setWorkHours(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "20px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
              <button
                type="button"
                onClick={createAgent}
                style={{
                  width: "100%",
                  padding: "14px",
                  borderRadius: "8px",
                  background: "#3f51b5",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Criar Agente
              </button>
              {message && (
                <VuiTypography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "red",
                  }}
                >
                  {message}
                </VuiTypography>
              )}
            </VuiBox>
          </VuiBox>
        ) : (
          <>
            {isLoadingQRCode && (
              <VuiBox
                sx={{
                  textAlign: "center",
                  p: 8,
                  borderRadius: "12px",
                  backgroundColor: "#1a2035",
                  maxWidth: "600px",
                  position: "relative",
                }}
              >
                <CircularProgress color="secondary" />
                <VuiTypography
                  variant="h4"
                  color="white"
                  fontWeight="bold"
                  mb={2}
                >
                  Aguardando, estamos gerando seu QR code...
                </VuiTypography>
              </VuiBox>
            )}

            {!isLoadingQRCode && qrCode && (
              <VuiBox
                sx={{
                  textAlign: "center",
                  p: 8,
                  borderRadius: "12px",
                  backgroundColor: "#1a2035",
                  maxWidth: "600px",
                  position: "relative",
                }}
              >
                <VuiTypography
                  variant="h4"
                  color="white"
                  fontWeight="bold"
                  mb={2}
                >
                  Escaneie o QR Code para se conectar
                </VuiTypography>
                <VuiBox sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                  {/* Exibe o QR Code como uma imagem usando o Data URI recebido */}
                  <img
                    src={qrCode}
                    alt="QR Code"
                    style={{
                      width: '256px', // Ajuste o tamanho conforme necessário
                      height: '256px', // Ajuste o tamanho conforme necessário
                      maxWidth: '100%', // Garante que a imagem não ultrapasse o contêiner
                      height: 'auto', // Mantém a proporção
                    }}
                  />
                </VuiBox>
              </VuiBox>
            )}
          </>
        )}
      </VuiBox>
    </DashboardLayout>
  );
};

// Placeholder para o componente ChatSection
const ChatSection = ({ agntWpp }) => {
  return (
    <VuiBox
      sx={{
        width: "100%",
        padding: "20px",
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(10px)",
      }}
    >
      <VuiTypography
        variant="h4"
        sx={{
          textAlign: "center",
          marginBottom: "20px",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        Chat Section
      </VuiTypography>
      {/* Implemente a lógica do chat aqui */}
      <VuiTypography variant="body1" color="white">
        Implementar a seção de chat aqui.
      </VuiTypography>
    </VuiBox>
  );
};

export default Whatsapp;
