import React, { useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import { IconButton } from "@mui/material";
import { IoAddCircleOutline } from "react-icons/io5";

const Whatsapp = () => {
  const [showContactForm, setShowContactForm] = useState(false); // Estado para controlar a visibilidade do formulário
  const [isInteracting, setIsInteracting] = useState(false); // Estado para verificar interação com a caixa
  let hoverTimer; // Variável para armazenar o temporizador

  const addContact = (event) => {
    event.preventDefault();

    const contactName = document.getElementById("newContactName").value.trim();
    let contactNumber = document.getElementById("newContactNumber").value.replace(/\D/g, "");

    if (contactNumber.length === 11 && contactNumber.startsWith("9", 2)) {
      contactNumber = contactNumber.slice(0, 2) + contactNumber.slice(3); // Remove o 9
    }

    const formattedNumber = `55${contactNumber}@c.us`;

    fetch(`/contact_names/${formattedNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: contactName }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Contato adicionado com sucesso!");
          document.getElementById("newContactName").value = "";
          document.getElementById("newContactNumber").value = "";
        } else {
          console.error("Erro ao adicionar o contato.");
        }
      })
      .catch((error) => {
        console.error("Erro na comunicação com o servidor:", error);
      });
  };

  const handleBulkAddContacts = () => {
    console.log("Adicionar contatos em massa");
  };

  // Função para mostrar o formulário ao passar o mouse
  const handleMouseEnter = () => {
    hoverTimer = setTimeout(() => setShowContactForm(true), 500); // Mostra após 0,5s
  };

  // Função para esconder o formulário quando o mouse sair, se não estiver interagindo
  const handleMouseLeave = () => {
    clearTimeout(hoverTimer); // Limpa o temporizador
    if (!isInteracting) {
      setShowContactForm(false); // Esconde o formulário se não estiver interagindo
    }
  };

  // Função para verificar se o usuário está interagindo com a caixa
  const handleInteractionStart = () => {
    setIsInteracting(true); // Marca como interagindo
  };

  // Função para finalizar a interação com a caixa
  const handleClickOutside = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsInteracting(false); // Marca como não interagindo
      setShowContactForm(false); // Esconde o formulário
    }
  };

  return (
    <VuiBox>
      <VuiTypography variant="h3" color="white" fontWeight="bold" mb={1}>
        WhatsApp
      </VuiTypography>
      <VuiTypography variant="body1" color="text" mb={3}>
        Adicione contatos para que seus clientes possam conversar com nossos agentes.
      </VuiTypography>

      {/* Container para adicionar contatos */}
      <VuiBox
        mb={3}
        p={2}
        sx={{
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!showContactForm ? (
          // Botão para adicionar contato
          <VuiButton
            color="info"
            variant="contained"
            fullWidth
            sx={{
              width: "100%", // Define o botão como full width
              maxWidth: "500px", // Define um tamanho máximo para o botão
              padding: "15px 0", // Aumenta a altura do botão
              fontSize: "16px", // Aumenta o tamanho da fonte do botão
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Adicionar Contato
          </VuiButton>
        ) : (
          // Formulário para adicionar contatos e botão de adicionar em massa
          <VuiBox
            component="form"
            onSubmit={addContact}
            display="flex"
            flexDirection="column"
            gap={1.5}
            alignItems="center"
            onMouseLeave={handleMouseLeave}
            onFocus={handleInteractionStart}
            onBlur={handleClickOutside}
            tabIndex={-1} // Permite que o evento de foco e desfoco funcione corretamente
            sx={{
              width: "100%", // Define o formulário como full width
              maxWidth: "500px", // Define um tamanho máximo para o formulário
              outline: "none", // Remove a borda de foco padrão
            }}
          >
            <VuiInput
              type="text"
              placeholder="Nome do Contato"
              id="newContactName"
              required
              fullWidth
              variant="outlined"
              sx={{
                background: "white",
                borderRadius: "5px",
                color: "black",
                "&::placeholder": { color: "grey" },
              }}
            />
            <VuiInput
              type="text"
              placeholder="Número de Telefone (ex: 11987654321)"
              id="newContactNumber"
              required
              fullWidth
              variant="outlined"
              sx={{
                background: "white",
                borderRadius: "5px",
                color: "black",
                "&::placeholder": { color: "grey" },
              }}
            />
            <VuiButton type="submit" color="info" variant="contained" fullWidth>
              Confirmar Adição
            </VuiButton>

            {/* Botão para Adicionar Contatos em Massa */}
            <VuiBox mt={2} display="flex" justifyContent="center" alignItems="center">
              <IconButton color="info" onClick={handleBulkAddContacts} sx={{ color: "white" }}>
                <IoAddCircleOutline size="30px" />
              </IconButton>
              <VuiTypography variant="button" color="white" ml={1}>
                Adicionar Contatos em Massa
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        )}
      </VuiBox>
    </VuiBox>
  );
};

export default Whatsapp;
