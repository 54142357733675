// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function Footer() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      component="footer"
      py={2}
      pb={0}
    >
      {/* Seção de Copyright */}
      <VuiBox sx={{ textAlign: "center", mb: { xs: 2, lg: 0 } }}>
        <VuiTypography
          variant="button"
          sx={{ fontWeight: "400 !important" }}
          color="white"
        >
          © 2024, Desenvolvido com ❤️ por{" "}
          <VuiTypography
            component="a"
            variant="button"
            href="https://agntia.com/"
            sx={{ fontWeight: "500 !important" }}
            color="white"
            mr="2px"
          >
            Agnt.iA
          </VuiTypography>
          para otimizar sua gestão.
        </VuiTypography>
      </VuiBox>

      {/* Seção de Termos de Uso */}
      <VuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
        <VuiBox>
          <VuiTypography
            component="a"
            href="https://agntia.com/termos"
            variant="body2"
            color="white"
          >
            Termos de Uso
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

export default Footer;
