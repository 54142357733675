import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/cardimgfree.png";

const WelcomeMark = () => {
  const [userName, setUserName] = useState(""); // Estado para armazenar o nome do usuário

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Primeiro, busca o token do usuário autenticado
        const tokenResponse = await axios.get("https://app.agntia.com:3001/api/get-token", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Use o token JWT salvo para autenticação
          },
        });

        const token = tokenResponse.data.token;

        // Agora, faz a requisição para buscar o nome do usuário pelo token JWT obtido
        const userProfileResponse = await axios.get("https://app.agntia.com:3001/api/user-profile", {
          headers: {
            Authorization: `Bearer ${token}`, // Incluindo o token JWT no header
          },
        });

        setUserName(userProfileResponse.data.name); // Definindo o nome do usuário no estado
      } catch (error) {
        console.error("Erro ao buscar o nome do usuário:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Card
      sx={{
        height: "340px",
        py: "32px",
        backgroundImage: `url(${gif})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
      }}
    >
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
            Bem-vindo de volta,
          </VuiTypography>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px">
            {userName || "Usuário"} {/* Exibindo o nome do usuário ou 'Usuário' como padrão */}
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="18px">
            Estamos felizes em te ver novamente!
            <br /> Pergunte-me qualquer coisa.
          </VuiTypography>
        </VuiBox>

        <VuiTypography
          component="a"
          href="https://app.agntia.com/agntia-gerente"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            "& .material-icons-round": {
              fontSize: "1.125rem",
              transform: `translate(2px, -0.5px)`,
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },
            "&:hover .material-icons-round, &:focus  .material-icons-round": {
              transform: `translate(6px, -0.5px)`,
            },
          }}
        >
          Conversar com Agnt.iA Gerente
          <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_forward</Icon>
        </VuiTypography>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
