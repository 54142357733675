// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Importando o favicon do diretório público
const favicon = process.env.PUBLIC_URL + '/favicon.ico'; // Substitua por 'favicon.png' se for esse o nome do arquivo

function SimmmpleLogo({ size }) {
  return (
    <img
      src={favicon} // Usando o favicon como fonte da imagem
      width={size}
      height={size}
      alt="Logo"
    />
  );
}

// Definindo valores padrão para as props de SimmmpleLogo
SimmmpleLogo.defaultProps = {
  color: "dark", // Esta propriedade não é mais necessária, mas mantida para compatibilidade
  size: "16px",
};

// Verificação de tipo para as props do SimmmpleLogo
SimmmpleLogo.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SimmmpleLogo;
