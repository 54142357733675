import React, { useEffect, useState } from "react";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Componentes do Dashboard
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography"; // Import necessário para o título da tabela
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";

// Importações corrigidas dos componentes
import WhatsApp from "layouts/profile/components/WhatsApp/WhatsApp";
import Table from "examples/Tables/Table"; // Importa o componente Table para exibir as tabelas
import authorsTableData from "layouts/tables/data/authorsTableData"; // Importa os dados da tabela de autores

function Overview() {
  const [userInfo, setUserInfo] = useState({
    nomeCompleto: "",
    email: "",
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        // Buscar o token do usuário autenticado do banco de dados
        const tokenResponse = await axios.get("https://app.agntia.com:3001/api/get-token", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Use o token JWT salvo para autenticação
          },
        });

        const token = tokenResponse.data.token;

        // Agora faz a requisição para buscar o nome do usuário pelo token JWT obtido
        const response = await axios.get("https://app.agntia.com:3001/api/user-profile", {
          headers: {
            Authorization: `Bearer ${token}`, // Incluindo o token JWT no header
          },
        });

        setUserInfo({
          nomeCompleto: response.data.name || "Nome não disponível",
          email: response.data.email || "Email não disponível",
        });
      } catch (error) {
        console.error("Erro ao buscar informações do usuário:", error);
      }
    };

    fetchUserInfo();
  }, []);

  // Obtém os dados da tabela de autores
  const { columns, rows } = authorsTableData;

  return (
    <DashboardLayout
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Garante que o layout ocupe a altura total da viewport
        backgroundSize: "cover", // Faz o fundo cobrir toda a tela
        backgroundPosition: "center center", // Centraliza o fundo
        backgroundRepeat: "no-repeat", // Não repete o fundo
        justifyContent: "space-between", // Espaça o conteúdo
        "@media (max-width: 960px)": {
          backgroundSize: "contain", // Ajusta o background para telas menores
        },
      }}
    >
      <Header /> {/* Exibe as informações do usuário no header */}
      <VuiBox
        flex={1} // Permite que o conteúdo principal cresça para ocupar o espaço disponível
        mt={5}
        mb={3}
        sx={{
          width: "100%", 
          maxWidth: "1200px", // Define um tamanho máximo para manter a responsividade
          padding: "0 20px", // Padding lateral para responsividade
          display: "flex",
          flexDirection: "column",
          gap: 3,
          "@media (max-width: 600px)": {
            padding: "0 10px", // Menos padding em telas menores
          },
        }}
      >
        <Card
          sx={{
            width: "100%",
            p: 2,
            mb: 3,
            backgroundColor: "transparent",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Ajuste para sombra suave
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "@media (max-width: 600px)": {
              p: 1, // Padding menor em telas menores
              mb: 2, // Margem menor em telas menores
            },
          }}
        >
          <WhatsApp />
        </Card>

        {/* Tabela de Autores */}
        <Card 
          sx={{ 
            mt: 3, 
            p: 2, 
            backgroundColor: "transparent", 
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "@media (max-width: 600px)": {
              p: 1, // Padding menor em telas menores
              mb: 2, // Margem menor em telas menores
            },
          }}
        >
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px" sx={{
            "@media (max-width: 600px)": {
              flexDirection: "column", // Ajusta a direção dos elementos em telas menores
              textAlign: "center",
            },
          }}>
            <VuiTypography variant="lg" color="white">
              Tabela de Contatos
            </VuiTypography>
          </VuiBox>
          <VuiBox
            sx={{
              width: "100%",
              overflowX: "auto", // Adiciona barra de rolagem horizontal se necessário
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Overview;
