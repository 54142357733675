import { useState } from "react";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Icons
import { FaFacebookF, FaGoogle } from "react-icons/fa";

// Images
import bgSignUp from "assets/images/signUpImage.png";

function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignUp = async () => {
    if (!name || !email || !password) {
      alert("Por favor, preencha todos os campos.");
      return;
    }

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://app.agntia.com:3001/register", true); // Use HTTPS no URL
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          try {
            const responseData = JSON.parse(xhr.responseText);
            if (xhr.status === 201) {
              alert("Usuário cadastrado com sucesso!");
              window.location.href = "/authentication/sign-in";
            } else {
              alert(responseData.message || "Algo deu errado. Por favor, tente novamente.");
            }
          } catch (e) {
            console.error("Erro ao processar a resposta do servidor:", e);
            alert("Erro ao processar a resposta do servidor. Por favor, tente novamente.");
          }
        }
      };

      // Envia o payload como uma string JSON pura
      xhr.send(
        JSON.stringify({
          name: name.trim(),
          email: email.trim(),
          password: password.trim(),
        })
      );
    } catch (error) {
      console.error("Erro ao cadastrar o usuário:", error);
      alert("Erro ao cadastrar o usuário. Por favor, tente novamente.");
    }
  };

  return (
    <CoverLayout
      title="Bem-vindo!"
      color="white"
      description="Crie sua conta para acessar o Dashboard da AgntIA"
      premotto="INSPIRADO NO FUTURO"
      motto="AGNTIA"
      image={bgSignUp}
    >
      <VuiBox mb={2} textAlign="center">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Entre com
        </VuiTypography>
        <VuiBox mt={2} display="flex" justifyContent="center" gap="10px">
          <VuiButton
            color="white"
            sx={{
              backgroundColor: "#3b5998",
              width: "48%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0",
              "&:hover": {
                backgroundColor: "#3b5998",
              },
              "&:active": {
                backgroundColor: "#3b5998",
              },
            }}
            onClick={() => alert("Login com Facebook")}
          >
            <FaFacebookF size="20px" />
          </VuiButton>
          <VuiButton
            color="white"
            sx={{
              backgroundColor: "#db4a39",
              width: "48%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0",
              "&:hover": {
                backgroundColor: "#db4a39",
              },
              "&:active": {
                backgroundColor: "#db4a39",
              },
            }}
            onClick={() => alert("Login com Google")}
          >
            <FaGoogle size="20px" />
          </VuiButton>
        </VuiBox>
      </VuiBox>
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Nome
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)"
          >
            <VuiInput
              type="text"
              placeholder="Seu nome..."
              fontWeight="500"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>

        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)"
          >
            <VuiInput
              type="email"
              placeholder="Seu email..."
              fontWeight="500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>

        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Senha
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)"
          >
            <VuiInput
              type="password"
              placeholder="Sua senha..."
              sx={{ fontSize: "14px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>

        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth onClick={handleSignUp}>
            CADASTRAR
          </VuiButton>
        </VuiBox>

        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Já tem uma conta?{" "}
            <VuiTypography
              component="a"
              href="/authentication/sign-in"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Faça login
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignUp;
