import { useState } from "react";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";

// Icons for social logins
import { FaFacebook, FaGoogle } from "react-icons/fa";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async () => {
    if (!email || !password) {
      alert("Por favor, preencha todos os campos.");
      return;
    }

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://app.agntia.com:3001/login", true); // Use HTTPS no URL
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          try {
            const responseData = JSON.parse(xhr.responseText);
            if (xhr.status === 200) {
              // Armazene o token no localStorage
              localStorage.setItem("token", responseData.token);
              alert("Login bem-sucedido!");
              // Redirecionar para o dashboard ap�s o login bem-sucedido
              window.location.href = "/dashboard";
            } else {
              alert(responseData.message || "Algo deu errado. Por favor, tente novamente.");
            }
          } catch (e) {
            console.error("Erro ao processar a resposta do servidor:", e);
            alert("Erro ao processar a resposta do servidor. Por favor, tente novamente.");
          }
        }
      };

      // Envia o payload como uma string JSON pura
      xhr.send(
        JSON.stringify({
          email: email.trim(),
          password: password.trim(),
        })
      );
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      alert("Erro ao fazer login. Por favor, verifique suas credenciais e tente novamente.");
    }
  };

  const handleSocialLogin = (provider) => {
    console.log(`Login com ${provider}`);
    // L�gica de login social vai aqui
  };

  return (
    <CoverLayout
      title="Inspirado no Futuro"
      color="white"
      description="Insira seu email e senha para acessar a AgntIA"
      premotto="BEM-VINDO DE VOLTA!"
      motto="AO DASHBOARD DA AGNTIA"
      image={bgSignIn}
    >
      <VuiBox mt={3} mb={2} textAlign="center">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Entre com
        </VuiTypography>
      </VuiBox>

      <VuiBox display="flex" justifyContent="center" mb={4}>
        <VuiButton color="info" onClick={() => handleSocialLogin('Facebook')} sx={{ mx: 1 }}>
          <FaFacebook size="20px" />
        </VuiButton>
        <VuiButton color="info" onClick={() => handleSocialLogin('Google')} sx={{ mx: 1 }}>
          <FaGoogle size="20px" />
        </VuiButton>
      </VuiBox>

      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)"
          >
            <VuiInput
              type="email"
              placeholder="Seu email..."
              fontWeight="500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Senha
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius="lg"
            backgroundImage="linear-gradient(135deg, #00c6ff 0%, #0072ff 100%)"
          >
            <VuiInput
              type="password"
              placeholder="Sua senha..."
              sx={{ fontSize: "14px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Lembrar-me
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth onClick={handleLogin}>
            ENTRAR
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            N�o tem uma conta?{" "}
            <VuiTypography
              component="a"
              href="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Cadastre-se
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Esqueceu sua Senha?{" "}
            <VuiTypography
              component="a"
              href="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Redefinir senha
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
