import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import Footer from "examples/Footer"; // Importando o Footer corretamente

// Vision UI Dashboard React context
import { useVisionUIController, setLayout } from "context";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <VuiBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        display: "flex",               // Define o layout como flexível
        flexDirection: "column",       // Direção das colunas
        minHeight: "100vh",            // Ocupa toda a altura da tela
        p: 3,
        position: "relative",
        backgroundSize: "cover",       // Faz o fundo cobrir toda a tela
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
      <VuiBox sx={{ marginTop: "auto", width: "100%" }}> {/* Posiciona o rodapé no final */}
        <Footer />
      </VuiBox>
    </VuiBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
