// Importação dos layouts do Vision UI Dashboard React
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Gerente from "layouts/gerente";  // Adicionado Gerente
import Whatsapp from "layouts/whatsapp";  // Adicionado Whatsapp

// Importação dos ícones do Vision UI Dashboard React
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { Redirect } from "react-router-dom";

// Função para verificar se o usuário está autenticado
const isAuthenticated = () => {
  return !!localStorage.getItem('token'); // Verificação simples usando localStorage
};

// Atualizando as rotas da aplicação
const routes = [
  {
    type: "collapse",
    name: "Painel de Controle",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: isAuthenticated() ? Dashboard : () => <Redirect to="/authentication/sign-in" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Contatos",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: isAuthenticated() ? Profile : () => <Redirect to="/authentication/sign-in" />,
    noCollapse: true,
  },
  { 
    type: "title", 
    title: "Central de Agentes",  // Renomeado para "Central de Agentes"
    key: "central-agents"
  },
  {
    type: "collapse",
    name: "Agentes",
    key: "tables",
    route: "/tables",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: isAuthenticated() ? Tables : () => <Redirect to="/authentication/sign-in" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Agnt.iA Gerente",
    key: "agntia-gerente",
    route: "/agntia-gerente",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: isAuthenticated() ? Gerente : () => <Redirect to="/authentication/sign-in" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Agnt.iA Whatsapp QR",
    key: "agntia-whatsapp",
    route: "/agntia-whatsapp",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: isAuthenticated() ? Whatsapp : () => <Redirect to="/authentication/sign-in" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
    hidden: true,  // Ocultar esta rota do sidebar
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
    hidden: true,  // Ocultar esta rota do sidebar
  },
  {
    type: "redirect",
    route: "/",
    to: isAuthenticated() ? "/dashboard" : "/authentication/sign-in", // Redireciona para o Dashboard se estiver autenticado, senão para Sign In
    key: "root",
  },
];

export default routes;
