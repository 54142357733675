import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";

// O componente Agent permanece, mas sem imagens de avatar
function Agent({ name, role }) {
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      {/* Removido o VuiAvatar para que a imagem não seja necessária */}
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {role}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

export default {
  columns: [
    { name: "agente", align: "left" },
    { name: "status", align: "center" },
    { name: "adicionado em", align: "center" },
    { name: "ação", align: "center" },
  ],

  rows: [
    // Nenhum agente adicionado no momento, mas estrutura preparada para futuros agentes
  ],
};
