import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

// Componentes do Dashboard
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header"; // Incluindo o Header

function Overview() {
  const [userInfo, setUserInfo] = useState({
    nomeCompleto: "",
    email: "",
    agentContent: [], // Adicionando agentContent no estado como array
  });

  const [messages, setMessages] = useState([
    { text: "Bem-vindo de volta! Como posso te ajudar hoje?", type: "bot" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [isAgentCreated, setIsAgentCreated] = useState(false); // Estado para verificar se o agente foi criado
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyAge, setCompanyAge] = useState("");
  const [workHours, setWorkHours] = useState("");

  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchUserInfo(); // Buscar informações do usuário ao carregar a página
  }, []);

  useEffect(() => {
    // Rola para a última mensagem após uma pequena pausa para garantir que a renderização esteja completa
    const timeoutId = setTimeout(() => {
      scrollToBottom();
    }, 100);

    return () => clearTimeout(timeoutId); // Limpa o timeout quando o componente é desmontado ou as mensagens mudam
  }, [messages]);

  const fetchUserInfo = async () => {
    try {
      const token = localStorage.getItem("token"); // Obtendo o token JWT do localStorage
      if (!token) {
        console.error("Token não encontrado no localStorage.");
        return;
      }

      const response = await axios.get("https://app.agntia.com:3001/api/user-profile", {
        headers: {
          Authorization: `Bearer ${token}`, // Incluindo o token JWT no header
        },
      });

      setUserInfo({
        nomeCompleto: response.data.name || "Nome não disponível",
        email: response.data.email || "Email não disponível",
        agentContent: response.data.agentContent || [], // Recuperando o conteúdo do agente
      });

      // Verifica se agentContent existe e atualiza o estado isAgentCreated
      if (response.data.agentContent && response.data.agentContent.length > 0) {
        setIsAgentCreated(true);
        fetchMessages(); // Carrega as mensagens do backend
      } else {
        setIsAgentCreated(false);
      }
    } catch (error) {
      console.error("Erro ao buscar informações do usuário:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token não encontrado no localStorage.");
        return;
      }

      const response = await axios.get("https://app.agntia.com:3001/api/get-agnnt-gerente-messages", {
        headers: {
          Authorization: `Bearer ${token}`, // Certifica-se de que o formato do token está correto
        },
      });

      if (response.data && Array.isArray(response.data)) {
        const loadedMessages = response.data.map((msg) => ({
          text: msg.message, // 'message' conforme o backend
          type: msg.sender === "bot" ? "bot" : "user",
        }));

        setMessages((prevMessages) => [...prevMessages, ...loadedMessages]);
      } else {
        console.error("Formato de resposta inesperado do backend.");
      }
    } catch (error) {
      console.error("Erro ao carregar mensagens do banco:", error);
    }
  };

  // Função para criar o agente
  const createAgent = async () => {
    let baseContent =
      "Você é um agente gerente que auxilia o gerenrte original,nunca invente dados, responsável por fazer a gestão toal do negocio com taticas e estrategias de mercado sempre auxliando o gerente da empresa a buscar e atender  clientes e fornecer informações atualizada de como esta sendo o negocio sobre produtos, serviços e processos da empresa. Responda de forma formatada e emojis quando necessário . Nunca mencione que é uma IA ou assistente virtual em hipotese alguma, nunca desvie do foco do gerenciamneto da empresa; sempre se apresente como um gerente real, funcionário da empresa. Responda de forma direta, clara e objetiva, com mensagens curtas e naturais, mantendo um limite maxim.o de conversas em 50 tokens e mande respostas do tamanho parecido com a pergunta do usuario para ser uma conversa atrativa e não um texto gigante sempre que envie uma conversa, e sempre entendendo o contexto. Sempre que possível, ofereça suporte, oriente o cliente e encaminhe para o departamento ou solução correta dentro da empresa.";
  
    const additionalContent = `${companyName ? " Nome da empresa: " + companyName : ""}${
      companyDescription ? " O que a empresa faz: " + companyDescription : ""
    }${companyAge ? " Tempo de existência da empresa: " + companyAge : ""}${
      workHours ? " um pouco mais da empresa: " + workHours : ""
    }`;
  
    const fullContent = `${baseContent} ${additionalContent}`;
  
    try {
      const token = localStorage.getItem("token"); // Obtendo o token JWT do localStorage
      if (!token) {
        console.error("Token não encontrado no localStorage.");
        return;
      }
  
      await axios.post(
        "https://app.agntia.com:3001/api/save-agent-content",
        { content: fullContent }, // Passa o content completo
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Agente criado com sucesso!");
  
      // Após a criação do agente, busque as informações mais recentes do usuário
      await fetchUserInfo();  // Atualize o estado do usuário para obter o agentContent atualizado
  
      setIsAgentCreated(true); // Marca o agente como criado
  
      // Carregue as mensagens agora que o agente foi criado
      fetchMessages();
    } catch (error) {
      console.error("Erro ao salvar o agente:", error);
    }
  };

  // Função para rolar automaticamente para a última mensagem
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const suggestedQuestions = [
    "Como posso te ajudar?",
    "Quais são os planos disponíveis?",
    "Como funciona a integração?",
  ];

  const getGroqChatCompletion = async (userMessage) => {
    const agentContentText = userInfo.agentContent.length > 0 ? userInfo.agentContent[0] : "Informações de agente não encontradas.";

    const context = [
      {
        role: "system",
        content: agentContentText,
      },
      { role: "user", content: userMessage },
    ];

    try {
      const response = await axios.post(
        "https://api.groq.com/openai/v1/chat/completions",
        {
          messages: context,
          model: "llama-3.1-70b-versatile",
        },
        {
          headers: {
            Authorization: `Bearer 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`,
          },
        }
      );

      return response.data.choices[0]?.message?.content || "Erro ao gerar resposta.";
    } catch (error) {
      console.error("Erro ao se comunicar com a API Groq:", error.message);
      return "Erro ao se comunicar com o servidor.";
    }
  };

  const saveMessage = async (message, sender) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token não encontrado no localStorage.");
        return;
      }

      await axios.post(
        "https://app.agntia.com:3001/api/save-agnnt-gerente-message", // Corrigido para a rota correta
        { message, sender },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Erro ao salvar mensagem:", error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    // Adiciona a nova mensagem do usuário no estado local
    setMessages((prevMessages) => [...prevMessages, { text: newMessage, type: "user" }]);
    setNewMessage(""); // Limpa o campo de entrada de mensagem imediatamente após o envio

    // Salva a nova mensagem no banco de dados
    await saveMessage(newMessage, "user");

    // Prepara o contexto para enviar para a Groq (mensagens carregadas + nova mensagem do usuário)
    const fullContext = [...messages, { text: newMessage, type: "user" }];

    // Cria o payload para enviar à API da Groq
    const groqResponse = await getGroqChatCompletion(
      fullContext.map((msg) => msg.text).join(" ") // Concatena todas as mensagens em um único contexto
    );

    // Adiciona a resposta da Groq ao estado local e salva no banco de dados
    setMessages((prevMessages) => [...prevMessages, { text: groqResponse, type: "bot" }]);
    await saveMessage(groqResponse, "bot"); // Salva a resposta do bot
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e);
    }
  };

  const handleSuggestionClick = (question) => {
    setNewMessage(question);
    setMessages((prevMessages) => [...prevMessages, { text: question, type: "user" }]);
  };

  return (
    <DashboardLayout
      sx={{
        height: "100vh",
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      {!isAgentCreated ? (
        <VuiBox
          sx={{
            maxWidth: "600px",
            margin: "auto",
            padding: "30px",
            background: "linear-gradient(135deg, #e0f7fa, #ffffff)",
            borderRadius: "20px",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid #e0e0e0",
            backdropFilter: "blur(8px)",
          }}
        >
          <VuiTypography
            variant="h4"
            sx={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#333",
              fontWeight: "bold",
            }}
          >
            Criação de Agente Gerente
          </VuiTypography>
          <input
            type="text"
            placeholder="Nome da Empresa"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "15px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />
          <input
            type="text"
            placeholder="O que a Empresa Faz"
            value={companyDescription}
            onChange={(e) => setCompanyDescription(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "15px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />
          <input
            type="text"
            placeholder="Tempo de Existência da Empresa"
            value={companyAge}
            onChange={(e) => setCompanyAge(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "15px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />
          <input
            type="text"
            placeholder="Como São as Jornadas de Trabalho"
            value={workHours}
            onChange={(e) => setWorkHours(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "20px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />
          <button
            onClick={createAgent}
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "10px",
              background: "#3f51b5",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
          >
            Criar Agente
          </button>
        </VuiBox>
      ) : (
        <VuiBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            padding: "20px",
            margin: 0,
            width: "100%",
            height: "calc(100vh - 60px)",
          }}
        >
          <VuiBox
            sx={{
              width: "100%",
              maxWidth: "1200px",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "10px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              backdropFilter: "blur(10px)",
            }}
          >
            <VuiBox sx={{ display: "flex", justifyContent: "center", gap: "10px", mb: 2 }}>
              {suggestedQuestions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => handleSuggestionClick(question)}
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#5c6bc0")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = "#3f51b5")}
                >
                  {question}
                </button>
              ))}
            </VuiBox>
            <VuiBox
              sx={{
                flex: 1,
                overflowY: "auto", // Rolagem vertical
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "calc(100% - 80px)",
                maxHeight: "500px", // Define uma altura máxima para a caixa de mensagens
                overflowX: "hidden", // Remove scroll horizontal
              }}
            >
              {messages.map((message, index) => (
                <VuiBox
                  key={index}
                  sx={{
                    alignSelf: message.type === "bot" ? "flex-start" : "flex-end",
                    backgroundColor: message.type === "bot" ? "#f0f0f0" : "#e0f7fa",
                    padding: "10px 15px",
                    borderRadius: "10px",
                    maxWidth: "60%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "pre-wrap", // Mantém a formatação de mensagens com múltiplas linhas
                    overflowWrap: "break-word", // Quebra palavras longas
                  }}
                >
                  <VuiTypography
                    variant="body2"
                    sx={{
                      color: "black",
                      padding: "10px",
                      wordBreak: "break-word", // Quebra palavras longas
                    }}
                  >
                    {message.text}
                  </VuiTypography>
                </VuiBox>
              ))}
              <div ref={messagesEndRef} />
            </VuiBox>
            <VuiBox
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "10px",
                padding: "10px",
                width: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                position: "sticky",
                bottom: 0,
                left: 0,
              }}
              onSubmit={handleSendMessage}
            >
              <textarea
                rows="1"
                placeholder="Digite sua mensagem..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  background: "transparent",
                  color: "white",
                  fontSize: "16px",
                  resize: "none",
                }}
              />
              <button
                type="submit"
                style={{
                  backgroundColor: "#3f51b5",
                  border: "none",
                  color: "white",
                  padding: "10px 15px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Enviar
              </button>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      )}
    </DashboardLayout>
  );
}

export default Overview;
